<template>
  <div>
    <el-card>
      <Combination :listName="title"></Combination>
      <div class="list-contetnt-header">
        <div class="list-content-select">
          <div class="list-title">组名称:</div>
          <el-input
            placeholder="请填写组名称"
            suffix-icon="el-icon-user"
            v-model="inputValue.groupName"
          >
          </el-input>
          <!-- <div class="list-title">是否激活:</div>
          <el-select
            v-model="inputValue.isActivied"
            placeholder="请选择"
            clearable
            class="select-width"
          >
            <el-option
              v-for="item in isOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select> -->

          <div class="list-title">组长账号:</div>
          <el-input
            placeholder="请填写公司账户"
            suffix-icon="el-icon-collection-tag"
            v-model="inputValue.groupAccount"
          >
          </el-input>

          <div class="list-title">所属工会:</div>
          <el-input
            placeholder="请填写工会名称"
            suffix-icon="el-icon-coordinate"
            v-model="inputValue.presidentName"
          >
          </el-input>
        </div>
        <div class="list-content-btn">
          <el-button type="info" plain @click="groupQuery()">查询</el-button>
        </div>
        <div
          class="list-contetn-add"
          v-if="this.isRole === '系统管理员' ? false : true"
        >
          <el-button type="info" plain @click="addGroup"
            >添加小组信息</el-button
          >
        </div>
      </div>
      <tables
        :ListArr="groupListArr"
        :tableList="groupTableList"
        :tablePagination="groupPagination"
        :editRow="editGroupRow"
        :deletes="deleteGroup"
        :currentChange="groupSizeChange"
        :sizeChange="groupCurrentChange"
        :loading="groupLoading"
        :isBtn="groupBtn"
        :use="isGroupUse"
      ></tables>
    </el-card>

    <el-dialog
      class="dialogBox"
      :close-on-click-modal="false"
      title="修改组长信息"
      :visible.sync="dialogVisibeeGroup"
      top="30vh"
      :show-close="false"
    >
      <el-row type="flex" justify="center">
        <el-col :xs="24" :sm="16" :md="12">
          <el-form id="from" :model="group" ref="ruleForm" label-width="100px">
            <!-- <input type="hidden" v-model="union.id" /> -->
            <el-form-item label="组名称:">
              <el-input v-model="group.groupName"></el-input>
            </el-form-item>
            <!-- <el-form-item label="是否激活:">
              <el-radio-group v-model="group.isActivied" disabled>
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </el-form-item> -->
            <el-form-item label="组长账号:">
              <el-select
                v-model="group.teamLeadId"
                placeholder="请选择会长名称"
                class="union-select"
              >
                <el-option
                  v-for="(item, index) in nOptions"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="备注:">
              <el-input v-model="group.remark" type="textarea"></el-input>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <el-row id="anniu22" type="flex">
        <el-col>
          <el-button type="success" @click="canceledits()">取消</el-button>
          <el-button type="primary" @click="submitFormUnion('ruleForm')"
            >确认</el-button
          >
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>
<script>
import Combination from '@/components/Combination.vue';
import addPresident from '../laborunion/addPresident';
import unionList from '../laborunion';
import tables from '../laborunion/table';
import {
  getGroupList,
  getEmployeeList,
  editGroup,
  deleteGroup,
  groupIsUse,
} from '@/service/moudules/platformApi';
import { getCookie } from '@/util/userLoginFn';
import { getLocalStorage, cleanLocalStorage } from '@/util/userLoginFn';
export default {
  components: {
    Combination,
    unionList,
    addPresident,
    tables,
  },
  data() {
    return {
      title: '小组信息',
      getGroupList,
      getCookie,
      getLocalStorage,
      cleanLocalStorage,
      groupIsUse,
      sigleuserGroup: {},
      group: {
        groupName: null,
        isActivied: 1,
        teamLeadId: null,
        remark: null,
        groupId: null,
      },
      groupTableList: [
        {
          name: '组名称',
          prop: 'groupName',
        },
        {
          name: '组ID',
          prop: 'groupId',
        },
        // {
        //   name: '是否激活',
        //   prop: 'isActivied',
        // },
        {
          name: '组长账号',
          prop: 'stuffName',
        },
        {
          name: '所属工会',
          prop: 'presidentName',
        },
        {
          name: '备注',
          prop: 'remark',
        },
        {
          name: '创建时间',
          prop: 'createdDatetime',
        },
        {
          name: '状态',
          prop: 'status',
        },
      ],
      inputValue: {
        groupName: null,
        isActivied: null,
        stuffName: null,
        presidentName: null,
      },
      groupPagination: {
        total: 0,
        pageSize: 10,
        currentPage: 1,
      },
      groupListArr: [],
      nOptions: [],
      groupLoading: false,
      dialogVisibeeGroup: false,
      isOptions: [
        {
          value: 0,
          label: '否',
        },
        {
          value: 1,
          label: '是',
        },
      ],
      groupRoleType: null,
      isRole: '',
      groupArr: [],
      groupBtn: 'groupBtn',
    };
  },
  methods: {
    //页数修改重新加载
    groupSizeChange(val) {
      this.groupPagination.currentPage = val;
      this.groupQuery();
    },
    groupCurrentChange(val) {
      this.groupPagination.pageSize = val;
      this.groupQuery();
    },
    // addEmployee() {},
    canceleditGroup() {
      this.dialogVisibleeditGroup = false;
      this.groupQuery('edit');
    },
    editGroupRow(row) {
      let {
        groupName,
        isActivied,
        teamLeadId,
        remark,
        createdDatetime,
        groupId,
      } = row;
      this.group.groupName = groupName;
      this.group.isActivied = isActivied === '是' ? 1 : 0;
      this.group.teamLeadId =
        teamLeadId == ''
          ? null
          : this.groupArr.find((item) => item.teamLeadId === teamLeadId)
              .stuffName;
      this.group.remark = remark;
      this.group.groupId = groupId;

      this.dialogVisibeeGroup = true;
    },
    async isGroupUse(row) {
      console.log(row, 'row');
      try {
        let { groupId, flag } = row;
        let params = {
          id: groupId,
          isFlag: flag === 0 ? 1 : 0,
        };

        let { code, data, msg } = await groupIsUse(params);

        if (code !== 200) {
          this.$message({
            type: 'error',
            message: msg,
          });
          return false;
        }

        this.$message({
          type: 'success',
          message: flag === 0 ? '启用成功' : '停用成功',
        });

        this.groupQuery();
      } catch (error) {
        console.log(error);
      }
    },
    addGroup() {
      this.$router.push({
        path: '/addGroup',
      });
    },

    canceledits() {
      this.dialogVisibeeGroup = false;
      this.$refs.ruleForm.resetFields();
    },
    async submitFormUnion() {
      try {
        let { isActivied, groupName, remark, teamLeadId, groupId } = this.group;
        let findRes = this.groupArr.find(
          (item) => item.stuffName === teamLeadId
        );
        let params = {
          isActivied,
          groupName,
          remark,
          teamLeadId: findRes ? findRes.teamLeadId : teamLeadId,
          groupId,
          sessionId: this.getCookie('sessionId'),
        };
        let { data, msg, code } = await editGroup(params);

        if (code !== 200) {
          this.$message({ type: 'error', message: msg });
          return false;
        }
        this.$message({
          type: 'success',
          message: '修改小组信息成功',
        });
        this.dialogVisibeeGroup = false;
        this.groupQuery();
      } catch (error) {
        console.log(error);
      }
    },

    async getEmployeeLists() {
      try {
        let params = `?pageNo=1&pageSize=200&type=2&${this.getCookie(
          'sessionId'
        )}`;

        const { code, msg, data } = await getEmployeeList(params);
        if (code !== 200 && data.records.length === 0) {
          this.$message({
            type: 'error',
            message: msg,
          });
          return false;
        }
        this.nOptions = data.records.map((item, index) => {
          return {
            value: item.stuffId,
            label: item.stuffName,
          };
        });
      } catch (error) {
        console.log(error);
      }
    },

    async deleteGroup(row) {
      try {
        const { groupId, teamLeadId } = row;
        let params = {
          id: groupId,
          teamLeadId,
          sessionId: this.getCookie('sessionId'),
        };

        this.$confirm('此操作将删除该条信息, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(async () => {
            let { data, code, msg } = await deleteGroup(params);
            if (code !== 200 && msg !== 'success') {
              this.$message({
                type: 'error',
                message: msg,
              });
              return false;
            }

            this.$message({
              type: 'success',
              message: '删除信息成功',
            });
            this.groupQuery();
          })
          .catch(() => {
            this.groupQuery();
            return false;
          });
      } catch (error) {
        console.log(error);
      }
    },

    //表格格式化
    codeFormatter(row, column, cellValue, index) {
      return cellValue == '' ? '空' : cellValue;
    },
    //点击查询数据
    async groupQuery() {
      try {
        this.groupLoading = true;
        let {
          groupName,
          isActivied,
          stuffName,
          presidentName,
        } = this.inputValue;

        let { pageSize, currentPage } = this.groupPagination;

        let params = {
          groupName,
          isActivied,
          stuffName,
          sessionId: this.getCookie(['name', 'sessionId']),
          pageSize,
          pageNo: currentPage,
          presidentName,
        };
        let { code, data, msg, total } = await getGroupList(params);

        if (code !== 200 && data.records.length === 0) {
          this.$message({
            type: 'error',
            message: msg,
          });
          this.groupLoading = false;
          return false;
        }
        this.groupListArr = data
          ? data.records.map((item) => {
              item.isActivied === 1
                ? (item.isActivied = '是')
                : (item.isActivied = '否');
              item.status = item.flag === 1 ? '停用' : '启用';
              return item;
            })
          : [];
        this.groupArr = [...this.groupListArr];
        console.log(this.groupArr, 'this.groupArr');
        this.groupPagination.total = data.total ? data.total : 0;
        this.$message({
          type: 'success',
          message: '获取小组信息成功',
        });
        this.groupLoading = false;
      } catch (error) {
        console.log(error);
      }
    },
  },
  created() {
    this.isRole = this.getLocalStorage('name');
    this.groupQuery();
    this.getEmployeeLists();
  },
};
</script>
<style lang="less">
.list-contetnt-header {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 20px;
  .list-content-select {
    display: flex;
    align-items: center;
    .list-title {
      margin-right: 10px;
      width: 60%;
      font-size: 14px;
      padding: 10px;
    }
  }
  .list-content-btn {
    margin: 0 10px;
  }
  .select-width {
    width: 100%;
  }
}
.union-select {
  width: 100%;
}
</style>
